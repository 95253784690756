import * as React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout";
import HomeRow from "../components/home/homeRow";

const Index = ({data}) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`;

  return (
    <Layout>
      <h1>Welcome to {siteTitle}</h1>
      <p>MS INTERNATIONAL plc is a public company quoted on the London Stock Exchange - AIM market and comprises four diverse operating divisions, 'Defence'; 'Forgings', 'Petrol Station Superstructures' and 'Corporate Branding', each holding a leading position in it's specialist markets.</p>
      <table className="homepage">
        <tbody>
          <HomeRow title="MSI-Defence Systems Ltd." address={["Salhouse Road,", <br />, "Norwich,", <br />, "NR7 9AY,", <br />, "England"]} description="Design, manufacture and service of defence equipment." Link="http://www.msi-dsl.com/" linkName="www.msi-dsl.com" />
          <HomeRow title="MSI-Defence Systems LLC" address={["1298 Galleria Boulevard,", <br />, "Rock Hill,", <br />, "SC 29730,", <br />, "USA"]} description="Design, manufacture and service of defence equipment." Link="http://www.msi-dsl.com/" linkName="www.msi-dsl.com" />
          <HomeRow title="MSI-Forks Ltd." address={["Balby Carr Bank,", <br />, "Doncaster,", <br />, "DN4 8DH", <br />, "England"]} description="Manufacture of fork-arms for the fork lift truck, construction, agricultural and quarrying equipment industries." Link="http://www.msi-forks.com/" linkName="www.msi-forks.com" />
          <HomeRow title="MSI-Forks LLC" address={["1298 Galleria Boulevard,", <br />, "Rock Hill,", <br />, "SC 29730", <br />, "USA"]} description="Manufacture of fork-arms for the fork lift truck, construction, agricultural and quarrying equipment industries." Link="http://www.msiforksinc.com/" linkName="www.msiforksinc.com" />
          <HomeRow title="MSI-Forks Garfos Industriais Ltda." address={["Rua Professor Campos,", <br />, "de Oliveira,", <br />, "310", <br />, "Sao Paulo", <br />, "Brazil"]} description="Manufacture of fork-arms for the fork lift truck, construction, agricultural and quarrying equipment industries." Link="http://www.msi-forks.com.br" linkName="www.msi-forks.com.br" />
          <HomeRow title="MSI-Quality Forgings Ltd." address={["Balby Carr Bank,", <br />, "Doncaster,", <br />, "DN4 8DH", <br />, "England"]} description="Manufacture of open die forgings." Link="http://www.msi-forge.com" linkName="www.msi-forge.com" />
          <HomeRow title="Global-MSI plc" address={["Balby Carr Bank,", <br />, "Doncaster,", <br />, "DN4 8DH", <br />, "England"]} description="Design, manufacture and construction of petrol station superstructures." Link="http://www.global-msi.com" linkName="www.global-msi.com" />
          <HomeRow title="Global-MSI Sp. z o.o." address={["Ul. Działowskiego 13,", <br />, "30-339 Krakow,", <br />, "Poland"]} description="Design, manufacture and construction of petrol station superstructures." Link="http://www.global-msi.pl" linkName="www.global-msi.pl" />
          <HomeRow title="MSI-Sign Group BV" address={["De Hoef 8,", <br />, "5311 GH Gameren,", <br />, "The Netherlands", <br />,<br />]} description="Design, restyling, production and installation of signage and wayfinding projects in the energy, automotive, hospitality, airports and corporate markets." Link="http://www.msisigngroup.com" linkName="www.msisigngroup.com" />
          <HomeRow title="MSI-Sign Group GmbH" address={["Wohlenbergstrasse 6 ", <br />, "30179 Hannover", <br />, "Germany"]} description="Design, restyling, production and installation of signage and wayfinding projects in the energy, automotive, hospitality, airports and corporate markets." Link="http://www.msisigngroup.com" linkName="www.msisigngroup.com" />
          <HomeRow title="Petrol Sign Ltd." address={["Balby Carr Bank,", <br />, "Doncaster", <br />, "DN4 8DH", <br />, "England"]} description="Design, restyling, production and installation of the complete appearance of petrol station superstructures and forecourts." Link="https://petrol-signuk.com/" linkName="www.petrol-signuk.com" />
          <HomeRow title="MS INTERNATIONAL Estates Ltd." address={["Balby Carr Bank,", <br />, "Doncaster,", <br />, "DN4 8DH", <br />, "England"]} description="Property holding company of the Group's UK properties." Link="http://www.msiplc.com" linkName="www.msiplc.com" />
          <HomeRow title="MS INTERNATIONAL Estates LLC" address={["1298 Galleria Boulevard,", <br />, "Rock Hill,", <br />, "SC 29730,", <br />, "USA"]} description="Property holding company of the Group's USA property." Link="http://www.msiplc.com" linkName="www.msiplc.com" />
        </tbody>
      </table>
    </Layout>
  )
}

export default Index

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`