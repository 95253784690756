import * as React from "react"

class HomeRow extends React.Component {
   
    render() {
        return (
            <tr className="relative">
                <td>{this.props.title}</td>
                <td>{this.props.address}</td>
                <td key={this.props.title}>{this.props.description} 
                    <a className="absolute right-0 bottom-2" href={this.props.Link} target="_blank" rel="noreferrer"><br />{this.props.linkName}</a>
                </td>
            </tr>
          )
    }
}

export default HomeRow